export const apiGetMerkleProof = async (
  chain: string,
  walletAddress: string,
  contractAddress: string
) => {
  const url = "https://api.nftport.xyz/v0/me/contracts/collections?";
  const query = new URLSearchParams({
    chain,
    include: "merkle_proofs",
  });

  const res = await fetch(url + query, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: process.env.REACT_APP_API_KEY!,
    },
  });
  const json = await res.json();

  const contractInfo = json.contracts.filter(
    (contract: any) =>
      contract.address.toLowerCase() === contractAddress.toLowerCase()
  );
  const merkleProofs = contractInfo[0].merkle_proofs || {};
  const merkleProof = merkleProofs[walletAddress.toLowerCase()] || [];
  return merkleProof;
};
